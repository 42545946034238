<template>
<v-dialog v-model="dialog" width="500" scrollable>
    <v-snackbar v-model="snackbar">{{snackbarMessage}}</v-snackbar>
    <v-card v-if="shareUrl != ''">
        <v-card-title>Wunsch teilen</v-card-title>
        <v-card-text>
            Mit diesem Link können Sie einer Arbeitskollegin / einem Arbeitskollegen diesen Wunsch schicken, wenn Sie der Meinung sind, er könnte für sie oder ihn interessant sein!
            <br><br>
            <v-text-field readonly v-model="shareUrl" dense outlined @focus="$event.target.select();copyToClipboard();"></v-text-field>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialog = false">OK</v-btn>
        </v-card-actions>
    </v-card>
    <v-card v-else>
        <v-card-title>Wunsch</v-card-title>
        <v-card-text v-if="sharedWish != null">
            <WishItem @takeWish="dialog = false" :wish="sharedWish" sharedWish></WishItem>
        </v-card-text>
        <v-card-text v-else>
            <v-alert type="info" color="primary">Dieser Wunsch wurde leider schon von einem anderen Mitarbeiter genommen.</v-alert>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialog = false">OK</v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>
<script>
import { collection, doc, onSnapshot } from '@firebase/firestore';
import WishItem from '../Items/WishItem.vue';
import { firestore } from '../../firebase';

export default {
    data() {
        return {
            snackbar: false,
            snackbarMessage: "",
            dialog: false,
            sharedWish: null,
            shareUrl: "",
            firebaseListener: null
        }
    },
    methods: {
        copyToClipboard() {
            navigator.clipboard.writeText(this.shareUrl).then(() => {
                this.snackbarMessage = "Der Link zum Teilen wurde in Ihre Zwischenablage kopiert!";
                this.snackbar = true;
            }).catch(err => {console.error(err)})
        }
    },
    watch: {
        dialog(newVal) {
            if(newVal) {
                if(this.shareID != "") {
                    this.firebaseListener = onSnapshot(doc(collection(firestore, "wishes"), this.shareID), doc => {
                        if(doc.exists) {
                            var newData = doc.data();
                            newData._id = doc.id;
                            this.sharedWish = newData;
                        }else{
                            this.sharedWish = null;
                        }
                    }, err => {
                        this.sharedWish = null;
                    });
                }else{
                    this.sharedWish = null;
                    this.shareUrl = this.url;
                }
            }else{
                if(this.firebaseListener != null) {
                    this.firebaseListener();
                    this.firebaseListener = null;
                }
                this.sharedWish = null;
                this.shareUrl = "";
            }
        },
        active(newVal) {
            this.dialog = true;
        }
    },
    destroyed() {
        if(this.firebaseListener != null) {
            this.firebaseListener();
            this.firebaseListener = null;
        }
    },
    props: {
        active: Number,
        url: String,
        shareID: String
    },
    components: {
        WishItem
    }
}
</script>