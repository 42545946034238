<template>
    <v-dialog v-model="showDialog" scrollable persistent max-width="550" dark>
        <v-card>
            <croppa v-model="croppa" :width="550" :height="450" :show-remove-button="false" placeholder="Bild auswählen" :prevent-white-space="false" canvas-color="#fff"></croppa>
            <v-card-actions>
                <v-btn icon large @click="rotate(-1)"><v-icon>mdi-rotate-left</v-icon></v-btn>
                <v-btn icon large @click="rotate(1)"><v-icon>mdi-rotate-right</v-icon></v-btn>
                <v-spacer></v-spacer>
                <v-btn text @click="clickAbort" :disabled="$store.state.isLoading">Abbrechen</v-btn>
                <v-btn text @click="clickSave" :disabled="$store.state.isLoading">Übernehmen</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    data() {
        return {
            showDialog: false,
            croppa: {}
        }
    },
    props: {
        active: Number
    },
    watch: {
        active(newVal, oldVal) {
            this.showDialog++;
            this.$store.state.isLoading = false;
            
            //this.croppa.chooseFile();
        }
    },
    methods: {
        rotate(steps) {
            this.croppa.rotate(steps);
        },
        clickSave() {
            this.$store.state.isLoading = true;
            this.croppa.generateBlob(blob => {
                if(blob == null) {
                    this.$store.state.isLoading = false;
                    return;
                }
                var reader = new FileReader();
                reader.readAsDataURL(blob); 
                reader.onloadend = () => {
                    var base64data = reader.result;
                    this.showDialog = false;
                    this.croppa.remove();
                    this.$store.state.isLoading = false;
                    this.$emit("save", base64data);
                }
            }, 'image/jpeg', 0.8);
        },
        clickAbort() {
            this.showDialog = false;
            this.croppa.remove();
            this.$emit("cancel");
        }
    }
}
</script>