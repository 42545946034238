<template>
<v-dialog persistent v-model="dialog" width="500">
    <v-card :loading="$store.state.isLoading" :disabled="$store.state.isLoading">
        <v-card-title>Rechteverwaltung</v-card-title>
        <v-list>
            <v-list-group v-for="permission in permissions" :key="permission._id">
                <template v-slot:activator>
                    <v-list-item class="px-0">
                        <v-list-item-avatar v-if="permission.picture">
                            <v-img :src="permission.picture"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-avatar v-else>
                            <v-icon class="grey lighten-1" dark>mdi-account</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>{{permission.name ? permission.name + " " + permission.family_name : permission._id}}</v-list-item-title>
                            <v-list-item-subtitle>{{permission.name ? permission._id : 'Noch nicht angenommen'}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </template>
                <v-btn @click="deletePermission(permission)" color="primary" small class="mx-5 mt-1 mb-2">Rechte entfernen</v-btn>
                <v-checkbox v-model="permission.users" label="Rechteverwaltung" hide-details inset class="px-5 my-0" color="primaryLight"></v-checkbox>
                <v-checkbox v-model="permission.wishes" label="Wunschverwaltung" hide-details inset class="px-5 my-0" color="primaryLight"></v-checkbox>
                <v-checkbox v-model="permission.campaign" label="Einstellungen der Wunschaktion" hide-details inset class="px-5 my-0" color="primaryLight"></v-checkbox>
                <v-btn @click="savePermission(permission)" color="primary" small class="mx-5 mt-2">Speichern</v-btn>
                <v-divider class="my-2"></v-divider>
            </v-list-group>
            <v-list-item>
                <v-list-item-avatar>
                    <v-icon class="grey lighten-1" dark>mdi-account</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-subtitle><v-text-field v-model="addPermissionsId" placeholder="Neue A-Kennung"  outlined dense hide-details color="primaryLight"></v-text-field></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                    <v-btn icon @click="addPermissions">
                        <v-icon color="primaryLight">mdi-plus</v-icon>
                    </v-btn>
                </v-list-item-action>
            </v-list-item>
        </v-list>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialog = false">OK</v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>
<script>
import { collection, deleteDoc, doc, onSnapshot, setDoc, updateDoc } from '@firebase/firestore';
import { firestore } from '../../firebase';

export default {
    data() {
        return {
            dialog: false,
            permissions: {},
            addPermissionsId: "",
            firebase: {
                listener: null
            }
        }
    },
    methods: {
        deletePermission(permission) {
            this.$store.state.isLoading = true;
            deleteDoc(doc(collection(firestore, "permissions"), permission._id)).then(() => {
                this.$store.state.isLoading = false;
            }).catch(err => {
                this.$store.state.isLoading = false;
            });
        },
        savePermission(permission) {
            this.$store.state.isLoading = true;
            updateDoc(doc(collection(firestore, "permissions"), permission._id), {
                users: permission.users,
                wishes: permission.wishes,
                campaign: permission.campaign
            }).then(() => {
                this.$store.state.isLoading = false;
            }).catch(err => {
                this.$store.state.isLoading = false;
            });
        },
        addPermissions() {
            if(this.addPermissionsId.toUpperCase().replace("@ITVV.ORG", "") == "") {
                return;
            }
            this.$store.state.isLoading = true;
            setDoc(doc(collection(firestore, "permissions"), this.addPermissionsId.toUpperCase().replace("@ITVV.ORG", "")), {
                campaign: false,
                users: false,
                wishes: false
            }).then(() => {
                this.addPermissionsId = "";
                this.$store.state.isLoading = false;
            }).catch(error => {
                this.$store.state.isLoading = false;
            });
        }
    },
    mounted() {
        this.firebase.listener = onSnapshot(collection(firestore, "permissions"), docs => {
            this.permissions = {};
            docs.forEach(doc => {
                var newDoc = doc.data(); 
                newDoc._id = doc.id;
                this.$set(this.permissions, doc.id, newDoc);
            });
        })
    },
    destroyed() {
        if(this.firebase.listener != null) {
            this.firebase.listener();
            this.firebase.listener = null;
        }
    },
    watch: {
        active() {
            this.dialog = true;
        }
    },
    props: {
        active: Number
    }
}
</script>