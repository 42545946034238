<template>
<div>
    <AlertDialog :title="alert.title" :message="alert.message" :active="alert.active"></AlertDialog>
    <ChooseImage @save="choosePicture" :active="dialogs.chooseImage"></ChooseImage>
    <v-dialog v-model="dialog" width="450" persistent>
        <v-card :loading="$store.state.isLoading" :disabled="$store.state.isLoading">
            <v-card-title>Informationen</v-card-title>
            <v-card-text>
                <v-card class="mx-auto">
                    <v-hover>
                        <template v-slot:default="{ hover }">
                            <v-img :src="image" :aspect-ratio="11/9" class="primary">
                                <v-fade-transition>
                                    <v-overlay v-if="hover" absolute color="primary">
                                        <v-btn @click="dialogs.chooseImage++" large><v-icon left>mdi-image</v-icon>Bild auswählen</v-btn>
                                    </v-overlay>
                                </v-fade-transition>
                            </v-img>
                        </template>
                    </v-hover>
                    <v-card-title>{{wish.title == "" ? 'Neuer Wunsch' : wish.title}}</v-card-title>
                    <v-card-text>
                        <v-text-field v-model="wish.title" label="Titel" prepend-icon="mdi-information" outlined dense maxlength="60" hint="Maximal 60 Zeichen"></v-text-field>
                        <v-text-field v-model="wish.name" label="Wunsch von" prepend-icon="mdi-account" outlined dense maxlength="60"></v-text-field>
                        <v-text-field v-model="wish.age" label="Alter" type="number" prepend-icon="mdi-calendar" outlined dense hint="0 = unbekanntes Alter"></v-text-field>
                        <v-text-field v-model="wish.link" label="Wunsch-URL" prepend-icon="mdi-web" outlined dense></v-text-field>
                        <!--<div>
                            Small plates, salads & sandwiches - an intimate setting with 12
                            indoor seats plus patio seating.
                        </div>-->
                    </v-card-text>
                </v-card>
            </v-card-text>
            <v-card-actions>
                <v-btn text @click="dialog=false">Abbrechen</v-btn>
                <v-spacer></v-spacer>
                <v-btn text @click="save">Speichern</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>
<script>
import { addDoc, collection, doc, serverTimestamp, updateDoc } from '@firebase/firestore';
import ChooseImage from './ChooseImage';
import { firestore, functions } from '../../firebase';
import { httpsCallable } from '@firebase/functions';
export default {
    data() {
        return {
            alert: {
                title: "",
                message: "",
                active: 0
            },
            image: "",
            url: "",
            dialog: false,
            wish: {
                title: "",
                name: "",
                age: 0,
                link: ""
            },
            rules: [
                value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
            ],
            dialogs: {
                chooseImage: 0
            }
        }
    },
    methods: {
        choosePicture(base64) {
            this.image = base64;
        },
        async save() {
            if(this.image == "") {
                this.alert.title = "Wunsch hinzufügen";
                this.alert.message = "Sie müssen ein Bild angeben, um diesen Wunsch hinzufügen zu können!";
                this.alert.active++;
                return;
            }
            if(this.wish.title == "") {
                this.alert.title = "Wunsch hinzufügen";
                this.alert.message = "Sie müssen ein Wunschtitel angeben, um diesen Wunsch hinzufügen zu können!";
                this.alert.active++;
                return;y
            }
            if(this.wish.name == "") {
                this.alert.title = "Wunsch hinzufügen";
                this.alert.message = "Sie müssen den Namen der Person angeben, die sich diesen Wunsch wünscht, um diesen Wunsch hinzufügen zu können!";
                this.alert.active++;
                return;
            }
            if(this.wish.link != "" && this.wish.link.indexOf("http://") === -1 && this.wish.link.indexOf("https://") === -1) {
                this.alert.title = "Wunsch hinzufügen";
                this.alert.message = "Die angegebene URL ist ungültig.";
                this.alert.active++;
                return;
            }
            var number = parseInt(this.wish.age);
            if(isNaN(number) || number < 0 || number >= 200) {
                this.alert.title = "Wunsch hinzufügen";
                this.alert.message = "Das eingegebene Alter ist ungültig";
                this.alert.active++;
                return;
            }
            this.$store.state.isLoading = true;
            try{
                const wishesRef = collection(firestore, "wishes")
                const addedDoc = await addDoc(wishesRef, {
                    title: this.wish.title,
                    name: this.wish.name,
                    age: this.wish.age,
                    link: this.wish.link,
                    uid: null,
                    visible: false,
                    timestamp: serverTimestamp()
                })
                const uploadPicture = httpsCallable(functions, "uploadPicture")
                const response = await uploadPicture({
                    wish: addedDoc.id,
                    picture: this.image
                })
                if(response.data.hasOwnProperty("error")) {
                    this.alert.title = "Wunsch hinzufügen";
                    this.alert.message = "Beim Hochladen des Bildes ist ein Fehler unterlaufen!";
                    this.alert.active++;
                }else{
                    await updateDoc(doc(wishesRef, addedDoc.id), {
                        visible: true
                    })
                    this.dialog = false
                }
            }catch(err) {
                this.alert.title = "Wunsch hinzufügen";
                this.alert.message = "Beim Speichern ist ein Fehler unterlaufen!";
                this.alert.active++;
                console.error(error);
            }
            this.$store.state.isLoading = false;
        }
    },
    watch: {
        active() {
            this.dialog = true;
            this.$set(this.wish, "title", "");
            this.$set(this.wish, "name", "");
            this.$set(this.wish, "age", 0);
            this.$set(this.wish, "link", "");
            this.image = "";
            this.url = "";
        }
    },
    props: {
        active: Number
    },
    components: {
        ChooseImage
    }
}
</script>