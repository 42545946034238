<template>
<div>
    <AlertDialog :title="alert.title" :message="alert.message" :active="alert.active"></AlertDialog>
    <ConfirmDialog @yes="setCampaignState(false)" :active="dialogs.stopCampaign" title="Wunschbaumaktion beeden" message="Möchten Sie die Wunschbaumaktion wirklich beenden? Dadurch können nur noch Mitarbeiter auf das System zugreifen, die Berechtigt sind."></ConfirmDialog>
    <ConfirmDialog @yes="setCampaignState(true)" :active="dialogs.startCampaign" title="Wunschbaumaktion starten" message="Möchten Sie die Wunschbaumaktion wirklich starten? Dadurch wird das System für alle Mitarbeiter zugänglich. Mitarbeiter werden nicht automatisch Benachrichtigt!"></ConfirmDialog>
    <ConfirmDialog @yes="deleteAllWishes" :active="dialogs.deleteWishes" title="Wünsche löschen" message="Möchten Sie wirklich alle Wünsche löschen? Das beinhaltet alle nicht genommenen und alle genommenen Wünsche und kann nicht mehr rückgängig gemacht werden! Diese Funktion sollte nach dem Ende der Wunschbaumaktion (zum Beispiel im Januar) genutzt werden.">
        <v-card-text>
            Geben Sie folgenden Sicherheitscode ein:
            <br>
            <b>{{securityCode}}</b>
            <v-text-field outlined dense label="Sicherheitscode" v-model="securityCodeInput" class="mt-4"></v-text-field>
        </v-card-text>
    </ConfirmDialog>
    <v-dialog v-model="dialog" width="400" persistent>
        <v-card :loading="$store.state.isLoading" :disabled="$store.state.isLoading">
            <v-card-title>Einstellungen</v-card-title>
            <v-list two-line>
                <v-list-item @click="changeCampaignState">
                    <v-list-item-content>
                        <v-list-item-title>{{$store.state.config.campaign.started ? 'Wunschbaumaktion beeden' : 'Wunschbaumaktion starten'}}</v-list-item-title>
                        <v-list-item-subtitle>{{$store.state.config.campaign.started ? 'Die Aktion ist aktuell im Gange' : 'Die Aktion ist aktuell beendet'}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="!$store.state.config.campaign.started" @click="dialogs.deleteWishes++">
                    <v-list-item-content>
                        <v-list-item-title>Alle Wünsche löschen</v-list-item-title>
                        <v-list-item-subtitle>Löscht alle(!) Wünsche und setzt das System zurück</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="dialog = false">OK</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>
<script>
import { collection, doc, updateDoc } from '@firebase/firestore';
import { firestore, functions } from '../../firebase';
import { httpsCallable } from '@firebase/functions';
export default {
    data() {
        return {
            alert: {
                title: "",
                message: "",
                active: 0
            },
            securityCode: "",
            securityCodeInput: "",
            dialogs: {
                stopCampaign: 0,
                startCampaign: 0,
                deleteWishes: 0
            },
            dialog: false,
        }
    },
    methods: {
        generateSecurityCode() {
            var result = '';
            var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < 16; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            this.securityCode = result;
        },
        deleteAllWishes() {
            if(this.securityCodeInput == this.securityCode) {
                this.$store.state.isLoading = true;
                const resetCampaign = httpsCallable(functions, "resetCampaign")
                resetCampaign().then(() => {
                    location.reload();
                }).catch(err => {
                    this.alert.title = "Fehler";
                    this.alert.message = "Es ist ein unbekannter Fehler aufgetreten. Bitte versuchen Sie es später erneut!";
                    this.alert.active++;
                });
            }else{
                this.alert.title = "Fehler";
                this.alert.message = "Der von Ihnen eingegebene Sicherheitscode stimmt nicht mit dem vorgegebenen Sicherheitscode überein!";
                this.alert.active++;
            }
        },
        changeCampaignState() {
            if(this.$store.state.config.campaign.started) {
                this.dialogs.stopCampaign++;
            }else{
                this.dialogs.startCampaign++;
            }
        },
        setCampaignState(state) {
            this.$store.state.isLoading = true;
            updateDoc(doc(collection(firestore, "configuration"), "campaign"), {
                started: state
            }).then(() => {
                location.reload();
            }).catch(error => {
                this.alert.title = "Fehler";
                this.alert.message = "Es ist ein unbekannter Fehler aufgetreten. Bitte versuchen Sie es später erneut!";
                this.alert.active++;
            });
        }
    },
    watch: {
        active() {
            this.dialog = true;
            this.generateSecurityCode();
            this.securityCodeInput = "";
        }
    },
    props: {
        active: Number
    }
}
</script>