<template>
<v-dialog v-model="dialog" width="500">
    <v-card :loading="isLoading" :disabled="isLoading">
        <v-card-title>Statistik</v-card-title>
        <v-list two-line>
            <v-list-item>
                <v-list-item-content>
                <v-list-item-title>Wünsche insgesamt</v-list-item-title>
                <v-list-item-subtitle>{{total}}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-content>
                <v-list-item-title>Wünsche genommen</v-list-item-title>
                <v-list-item-subtitle>{{taken}}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-content>
                <v-list-item-title>Wünsche / Insgesamt in %</v-list-item-title>
                <v-list-item-subtitle>{{total == 0 ? 100 : (taken / total * 100).toFixed(2)}}%</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialog = false">OK</v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>
<script>
import { collection, doc, getDoc } from '@firebase/firestore';
import { httpsCallable } from '@firebase/functions';
import { firestore, functions } from '../../firebase';
export default {
    data() {
        return {
            isLoading: false,
            dialog: false,
            total: 0,
            taken: 0
        }
    },
    methods: {
        async loadStats() {
            this.isLoading = true
            const getStats = await httpsCallable(functions, "getStats")
            const { data } = await getStats()
            this.total = data.total
            this.taken = data.taken
            this.isLoading = false
            console.log(data)
        }
    },
    watch: {
        active() {
            this.dialog = true;
            this.loadStats();
        }
    },
    props: {
        active: Number
    }
}
</script>